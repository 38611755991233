import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';
import { CategoryTreeViewModel, CopyProductRequest, CreateProductCategoryRequest, CreateProductGroupRequest, CreateProductRequest, ProductCategoriesViewModel, ProductCategoryViewObject, ProductCustomerAssetsViewModel, ProductGroupViewObject, ProductGroupsViewModel, ProductImportResultViewModel, ProductImportSettings, ProductViewObject, ProductsByIdRequest, ProductsViewModel, UpdateProductAssetsRequest, UpdateProductCategoriesForProductRequest, UpdateProductCategoryParentRequest, UpdateProductCategoryRequest, UpdateProductCategorySortOrderRequest, UpdateProductCustomerAccessesRequest, UpdateProductCustomerAssetsRequest, UpdateProductGroupCustomersRequest, UpdateProductGroupProductAccessRequest, UpdateProductGroupProductAccessesRequest, UpdateProductGroupProductsRequest, UpdateProductGroupRequest, UpdateProductImagesRequest, UpdateProductRequest, UpdateProductSEORequest, UpdateProductVariantsRequest, UpdateProductsForProductCategoryRequest } from '@/api';

const api = apiInstanceFactory();

class ProductsService extends ApiBase {
    public async getProduct(productId: string): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsGetProductList({ productId }));
    }

    public async getProductGroup(groupId: string): Promise<ProductGroupViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsGetProductGroupList({ groupId }));
    }

    public async getProducts(): Promise<ProductsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsGetProductsList());
    }
    
    public async getAvailableProducts(): Promise<ProductsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsGetAvailableProductsList());
    }

    public async getAvailableProductsForCustomer(): Promise<ProductsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsGetAvailableProductsForCustomerList());
    }

    public async getProductsByIds(request: ProductsByIdRequest): Promise<ProductsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsProductsByIdCreate(request));
    }

    public async getProductGroups(): Promise<ProductGroupsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsGetProductGroupsList());
    }

    public async getCategoryTree(query?: {
        includeProducts?: boolean;
        sort?: boolean;
      }): Promise<CategoryTreeViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsGetCategoryTreeList(query));
    }

    public async getProductCategories(): Promise<ProductCategoriesViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsGetProductCategoriesList());
    }

    public async createProduct(request: CreateProductRequest): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsCreateProductCreate(request));
    }
    
    public async copyProduct(request: CopyProductRequest): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsCopyProductCreate(request));
    }

    public async updateProduct(request: UpdateProductRequest): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductCreate(request));
    }

    public async deleteProduct(productId: string): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsDeleteProductDelete({ productId }));
    }

    public async updateProductSEO(request: UpdateProductSEORequest): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductSeoCreate(request));
    }

    public async createProductGroup(request: CreateProductGroupRequest): Promise<ProductGroupViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsCreateProductGroupCreate(request));
    }

    public async updateProductGroup(request: UpdateProductGroupRequest): Promise<ProductGroupViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductGroupCreate(request));
    }

    public async deleteProductGroup(groupId: string): Promise<ProductGroupViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsDeleteProductGroupDelete({ groupId }));
    }

    public async updateProductImages(request: UpdateProductImagesRequest): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsAddOrUpdateProductImagesCreate(request));
    }

    public async updateProductAssets(request: UpdateProductAssetsRequest): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsAddOrUpdateProductAssetsCreate(request));
    }
    
    public async updateProductGroupAccessForProduct(request: UpdateProductGroupProductAccessRequest): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductGroupAccessForProductCreate(request));
    }

    public async updateCustomerProductAccessForProduct(request: UpdateProductCustomerAccessesRequest): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateCustomerProductAccessForProductCreate(request));
    }

    public async updateProductCategoriesForProduct(request: UpdateProductCategoriesForProductRequest): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductCategoriesForProductCreate(request));
    }

    public async updateProductGroupAccessForProducts(request: UpdateProductGroupProductAccessesRequest): Promise<ProductsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductGroupAccessForProductsCreate(request));
    }

    public async updateProductsForProductGroup(request: UpdateProductGroupProductsRequest): Promise<ProductGroupViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductsForProductGroupCreate(request));
    }
    
    public async updateCustomersForProductGroup(request: UpdateProductGroupCustomersRequest): Promise<ProductGroupViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateCustomersForProductGroupCreate(request));
    }

    public async deleteProductCategory(categoryId: string, recursive: boolean): Promise<ProductCategoriesViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsDeleteProductCategoryDelete({ categoryId, recursive }));
    }

    public async createProductCategory(request: CreateProductCategoryRequest): Promise<ProductCategoryViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsCreateProductCategoryCreate(request));
    }

    public async updateProductCategory(request: UpdateProductCategoryRequest): Promise<ProductCategoryViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductCategoryCreate(request));
    }

    public async updateProductCategoryParent(request: UpdateProductCategoryParentRequest): Promise<ProductCategoryViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductCategoryParentCreate(request));
    }
    
    public async updateProductCategorySort(request: UpdateProductCategorySortOrderRequest): Promise<ProductCategoriesViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductCategorySortCreate(request));
    }

    public async updateProductsForProductCategory(request: UpdateProductsForProductCategoryRequest): Promise<ProductCategoryViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductsForProductCategoryCreate(request));
    }

    public async importProductsFromWordpressFeed(settings: ProductImportSettings): Promise<ProductImportResultViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsImportProductsFromWordPressFeedCreate(settings));
    }

    public async addOrUpdateProductCustomerAssets(request: UpdateProductCustomerAssetsRequest): Promise<ProductCustomerAssetsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsAddOrUpdateProductCustomerAssetsCreate(request));
    }

    public async getProductCustomerAssets(productId: string, customerId: string): Promise<ProductCustomerAssetsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsGetProductCustomerAssetsList({ productId, customerId }));
    }

    public async updateProductVariants(request: UpdateProductVariantsRequest): Promise<ProductViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.productsUpdateProductVariantsForProductCreate(request));
    }
}

export default new ProductsService();
