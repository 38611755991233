<template>
    <header>
        <nav class="px-5 sm:px-0">
            <div class="container flex items-center h-full">
                <div class="logo-container">
                    <router-link :to="{ name: $routes.HOME }" class="flex items-center">
                        <img src="/assets/images/logo.png" alt="bolig-swipe-logo" class="logo-image">
                    </router-link>
                </div>

                <BreakPoints>
                    <template #sm>
                        <div class="ml-auto flex items-center gap-4 md:gap-5 h-full">
                            <HeaderDropdown class="flex items-center">
                                <template #default="{ active }">
                                    <router-link :to="{ name: $routes.PRODUCT_CATEGORIES_PAGE }" 
                                                 class="text-14 md:text-16 font-bold flex flex-col sm:flex-row items-center sm:gap-2"
                                                 :class="{ 'text-green-primary': active }">
                                        <CIcon name="barrel" height="25" width="25"/>
                                        <p class="text-13 sm:text-16">
                                            {{ $translate('Header.Products') }}
                                        </p>
                                    </router-link>
                                </template>

                                <template #menu>
                                    <HeaderDropdown_Products/>
                                </template>
                            </HeaderDropdown>
                            <div class="vertical-divider"/>
                            <router-link :to="{ name: $routes.PRODUCTS }"
                                         class="text-14 md:text-16 font-bold flex flex-col sm:flex-row items-center sm:gap-2 hover:text-green-primary">
                                <CIcon name="magnify" height="25" width="25"/>
                                <p class="text-13 sm:text-16 truncate">
                                    {{ $translate('Header.Search') }}
                                </p>
                            </router-link>
                            <div class="vertical-divider"/>
                            <template v-if="user">
                                <router-link :to="{ name: $routes.ORDER_HISTORY_PAGE }"
                                             class="text-14 md:text-16 font-bold flex flex-col sm:flex-row items-center sm:gap-2 hover:text-green-primary">
                                    <CIcon name="clipboard-check-outline" height="25" width="25"/>
                                    <p class="text-13 sm:text-16 truncate">
                                        {{ $translate('Header.OrderHistory') }}
                                    </p>
                                </router-link>
                                <div class="vertical-divider"/>
                            </template>
                            <Transition
                                mode="out-in"
                                appear
                                :enter-active-class="enterActiveClass"
                                :leave-active-class="leaveActiveClass">
                                <button v-if="!isLoggedIn" class="text-14 md:text-16 font-bold flex flex-col sm:flex-row items-center sm:gap-2" @click="login()">
                                    <CIcon name="login" height="25" width="25"/>
                                    <p class="text-13 sm:text-16 truncate">
                                        {{ $translate('Header.LogIn') }}
                                    </p>
                                </button>
                                <HeaderDropdown v-else-if="user" class="flex items-center">
                                    <template #default="{ active }">
                                        <div class="font-bold flex flex-col sm:flex-row items-center sm:gap-2" 
                                             :class="{ 'text-green-primary': active }">
                                            <CIcon name="account" height="25" width="25"/>
                                            <p class="text-13 sm:text-16">
                                                {{ user.name }}
                                            </p>
                                        </div>
                                    </template>

                                    <template #menu>
                                        <HeaderDropdown_Profile/>
                                    </template>
                                </HeaderDropdown>
                            </Transition>
                            <template v-if="user">
                                <div class="vertical-divider"/>
                                <button v-if="activeCustomer"
                                        class="text-14 md:text-16 font-bold flex flex-col sm:flex-row items-center sm:gap-2 hover:text-green-primary"
                                        @click="selectCustomer()">
                                    <CIcon name="domain" height="25" width="25"/>
                                    <p class="text-13 sm:text-16 truncate">
                                        {{ activeCustomer.name }}
                                    </p>
                                </button>
                                <button v-else class="text-14 md:text-16 font-bold flex flex-col sm:flex-row items-center sm:gap-2" @click="selectCustomer()">
                                    <CIcon name="domain" height="25" width="25"/>
                                    <p class="text-13 sm:text-16 truncate">
                                        {{ $translate('Header.SelectCustomer') }}
                                    </p>
                                </button>
                                <template v-if="activeCustomer">
                                    <div class="vertical-divider"/>
                                    <HeaderDropdown class="flex items-center">
                                        <template #default="{ active }">
                                            <router-link :to="{ name: $routes.CHECKOUT_PAGE }" 
                                                         class="text-14 md:text-16 font-bold flex flex-col sm:flex-row items-center sm:gap-2 relative"
                                                         :class="{ 'text-green-primary': active }">
                                                <CIcon name="basket" height="25" width="25"/>
                                                <p class="text-13 sm:text-16 truncate">
                                                    {{ $translate('Header.Basket') }}
                                                </p>
                                                <span v-if="cart?.lineItems?.length"
                                                      class="basket-size-indicator"
                                                      :class="{ 'animate__animated animate__pulse animate__duration_300 animate__infinite': applyCartAnim }">
                                                    {{ cart.lineItems.length }}
                                                </span>
                                            </router-link>
                                        </template>

                                        <template #menu="{ close }">
                                            <HeaderDropdown_MiniBasket :close="close"/>
                                        </template>
                                    </HeaderDropdown>
                                </template>
                            </template>
                        </div>
                    </template>

                    <template #mobile-sm>
                        <div class="ml-auto flex items-center gap-4 md:gap-5 h-full">
                            <template v-if="user">
                                <button v-if="activeCustomer" class="text-14 md:text-16 font-bold flex flex-col sm:flex-row items-center sm:gap-2" @click="selectCustomer()">
                                    <CIcon name="domain" height="25" width="25"/>
                                    <p class="text-13 sm:text-16 truncate">
                                        {{ activeCustomer.name }}
                                    </p>
                                </button>
                                <button v-else class="text-14 md:text-16 font-bold flex flex-col sm:flex-row items-center sm:gap-2" @click="selectCustomer()">
                                    <CIcon name="domain" height="25" width="25"/>
                                    <p class="text-13 sm:text-16 truncate">
                                        {{ $translate('Header.SelectCustomer') }}
                                    </p>
                                </button>
                                <div class="vertical-divider"/>
                            </template>

                            <Transition
                                mode="out-in"
                                appear
                                :enter-active-class="enterActiveClass"
                                :leave-active-class="leaveActiveClass">
                                <button v-if="!isLoggedIn" class="text-14 md:text-16 font-bold flex flex-col sm:flex-row items-center sm:gap-2" @click="login()">
                                    <CIcon name="login" height="25" width="25"/>
                                    <p class="text-13 sm:text-16 truncate">
                                        {{ $translate('Header.LogIn') }}
                                    </p>
                                </button>
                                <HeaderDropdown v-else-if="user" class="flex items-center" anchor="right">
                                    <template #default="{ active }">
                                        <div class="font-bold flex flex-col sm:flex-row items-center sm:gap-2" :class="{ 'text-border': active }">
                                            <CIcon name="account" height="25" width="25"/>
                                            <p class="text-13 sm:text-16">
                                                {{ user.name }}
                                            </p>
                                        </div>
                                    </template>

                                    <template #menu>
                                        <HeaderDropdown_Profile/>
                                    </template>
                                </HeaderDropdown>
                            </Transition>
                        </div>
                    </template>
                </BreakPoints>
            </div>
        </nav>
    </header>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, ref, watch } from 'vue';
import { userStore } from '@/core/store/user/user.store';
import { useAnimation } from '@/core/animation/animateComposable';
import authenticationService from '@/core/authentication/authentication.service';
import dialogService from '@/core/dialog/dialog.service';

import HeaderDropdown from './HeaderDropdown.vue';
import HeaderDropdown_Profile from './HeaderDropdown_Profile.vue';
import HeaderDropdown_Products from './HeaderDropdown_Products.vue';
import HeaderDropdown_MiniBasket from './HeaderDropdown_MiniBasket.vue';

export default defineComponent({
    name: 'HeaderComponent',
    components: { HeaderDropdown, HeaderDropdown_Profile, HeaderDropdown_Products, HeaderDropdown_MiniBasket },
    setup() {
        const { isLoggedIn, user, activeCustomer, cart } = userStore;
        const { enterActiveClass, leaveActiveClass } = useAnimation({ type: 'fade' });

        const applyCartAnim = ref(false);
        let clearAnimTimeout: number | undefined;

        watch(() => cart.value?.lineItems?.length, () => {
            applyCartAnim.value = true;

            clearInterval(clearAnimTimeout);
            clearAnimTimeout = setInterval(() => applyCartAnim.value = false, 600);
        });
        
        return {
            cart,
            user,
            applyCartAnim,
            activeCustomer,
            isLoggedIn,
            scrollBarWidth: computed(() => `${window.scrollbarWidth.value}px`),
            enterActiveClass,
            leaveActiveClass,

            login: async() => {
                await authenticationService.loginPrompt('PRODUCTS');
            },

            selectCustomer: () => {
                dialogService.showModal({
                    component: defineAsyncComponent({ loader: () => import('@/components/authentication/SelectCustomerForm.vue') }),
                }, {
                    size: 'xs',
                });
            },
        };
    },
});
</script>

<style lang="scss" scoped>
header {
    nav {
        background-color: theme('colors.background');
        flex-shrink: 0;
        height: 60px;
        border-bottom: 3px solid theme('colors.green.primary');
        position: relative;

        @screen sm {
            height: 75px;
        }

        .logo-container {
            user-select: none;
            display: flex;
            align-items: center;
            flex-shrink: 0;

            .logo-image {
                margin-top: 3px;
                height: 40px;
                width: 110px;
                margin-right: 5px;
                flex-shrink: 0;

                @screen md {
                    height: 50px;
                    width: 195px;
                    margin-right: 10px;
                }
            }
        }
    }
}

.vertical-divider {
    height: 20px;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    margin: 0 5px;

    @screen sm {
        margin: 0 1px;
    }
}

.unread-messages-count {
    position: absolute;
    top: -10px;
    right: -15px;
    color: theme('colors.green.primary');
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.basket-size-indicator {
    position: absolute;
    top: -11px;
    right: 33px;
    font-weight: medium;
    font-size: 13px;
    background-color: theme('colors.yellow.300');
    border-radius: 9999px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
}

.router-link-exact-active {
    color: theme('colors.green.primary');
}
</style>
